* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Alegreya", sans-serif, serif;
}
.projekte {
  border: 2px solid red;
  width: 500px;
  height: 500px;
}
#root {
  overflow: hidden;
}
