.about_s_two {
  background-color: #eef0ed;
  padding: 100px;
  .aboutSTwo_text {
    text-align: center;
    h1 {
      font-size: 38px;
      margin-bottom: 20px;
    }
    p {
      font-size: 24px;
      width: 85%;
      margin: auto;
    }
  }

  .aboutSTwo_content {
    margin-top: 80px;
    .one-card {
      width: 25%;
      img {
        width: 100%;
        height: 270px;
        border-radius: 15px;
      }
      .name {
        font-size: 30px;
        font-weight: 600;
        margin: 20px 0 5px 0;
      }
      .details {
        font-size: 20px;
      }
      .socials {
        width: 120px;
        margin-top: 20px;
        svg {
          width: 30px;
          height: auto;
          border-radius: 20%;
          transition: all 0.3s linear;
          &:hover {
            transform: scale(1.2);
          }
        }
      }
    }
  }

  @media screen and (max-width: 1024px) {
    padding: 50px;
    .aboutSTwo_content {
      .one-card {
        width: 30%;
      }
    }
  }
  @media screen and (max-width: 780px) {
    padding: 30px;
    .aboutSTwo_text {
      h1 {
        font-size: 32px;
      }
      p {
        width: 100%;
        font-size: 22px;
      }
    }
    .aboutSTwo_content {
      .one-card {
        width: 45%;
        margin-bottom: 80px;
        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
  }
  @media screen and (max-width: 600px) {
    padding: 20px;
    .aboutSTwo_content {
      .one-card {
        width: 51%;
      }
    }
  }
  @media screen and (max-width: 500px) {
    .aboutSTwo_content {
      .one-card {
        width: 75%;
      }
    }
  }
}
