.home_s_five {
  height: 400px;
  background: linear-gradient(rgba(154, 154, 154, 0.65), rgba(0, 0, 0, 0.1));
  position: relative;
  .title {
    width: 90%;
  }
  h1 {
    font-size: 40px;
    color: rgb(10, 10, 10);
    text-align: center;
    font-weight: 500;
    overflow: hidden;
    white-space: nowrap;
    animation: typing 3.5s steps(40, end), blink-caret 0.75s step-end;
  }
  a {
    margin-top: 60px;
    padding: 10px 20px;
    width: 230px;
    font-size: 22px;
    text-align: center;
    color: black;
    border: 2px solid black;
    transition: all 0.3s ease-in !important;
    &:hover {
      transform: scale(1.1) !important;
      border-radius: 20px;
      background-color: white;
    }
  }

  @media screen and (max-width: 780px) {
    h1 {
      animation: none;
      font-size: 40px;
      overflow: initial;
      white-space: initial;
    }
  }
  @media screen and (max-width: 600px) {
    .title {
      width: 100%;
    }
    h1 {
      font-size: 30px;
    }
    a {
      font-size: 20px;
      width: 180px;
    }
  }
}

@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

@keyframes blink-caret {
  from,
  to {
    border-color: transparent;
  }
  50% {
    border-color: black;
  }
  100% {
    border-color: transparent;
  }
}
