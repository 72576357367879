.cookies {
  background-color: #000;
  position: fixed;
  bottom: 0;
  padding: 30px 30px 8px 30px;
  left: 0;
  right: 0;
  color: #fff;
  z-index: 100000 !important;
  h3 {
    font-weight: normal;

    z-index: 100000 !important;
  }
  .cookies-buttons {
    width: fit-content;
    margin-left: auto;
    margin-right: 20px;
    margin-top: 20px;

    z-index: 100000 !important;
    .cookies-button {
      text-decoration: none;
      border: none;
      cursor: pointer;
      padding: 10px 15px;
      margin: 0 10px;
      font-size: 18px;
      border-radius: 5px;
      transition: all 0.3s ease-in-out;
      z-index: 100000 !important;
      background-color: white;
      color: #000;
      &:hover {
        background-color: rgb(97, 97, 97);
        color: #fff;
      }
    }
  }

  @media screen and(max-width: 600px) {
    padding: 20px;
    height: fit-content;
    .cookies-buttons {
      margin-left: initial;
      .cookies-button {
        margin-top: 10px;
        padding: 5px 10px;
      }
    }
  }
}
