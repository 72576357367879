.contact_s_two {
  padding: 50px 100px;
  color: #fff;
  background-color: #18191d;
  .contactSOne_SocialsSide,
  .contactSOne_SendEmailSide,
  .success_msg_send {
    width: 45%;
  }

  .contactSOne_SocialsSide {
    h1 {
      font-size: 35px;
    }
    .icons {
      width: 200px;
      margin-top: 20px;
      a {
        display: flex;
        align-items: center;
        width: 200px;
        margin-bottom: 20px;
        p {
          font-size: 25px;
          color: #fff;
          margin-left: 15px;
          position: relative;
          left: 0;
          transition: all 0.3s linear;
        }
        svg {
          width: 50px;
          height: auto;
          border-radius: 20%;
          transition: all 0.4s linear;
        }
        &:hover {
          p {
            color: rgb(163, 163, 163);
            left: 10px;
            left: 10px;
          }
          svg {
            transform: rotate(360deg);
          }
        }
      }
    }
  }
  .contactSOne_SendEmailSide {
    .input_group {
      margin-bottom: 30px;
      width: 100%;
      &:last-of-type {
        margin-bottom: 0;
      }
      p {
        font-size: 23px;
        font-weight: 600;
        margin-bottom: 10px;
        font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
      }
      input,
      textarea {
        width: 100%;
        padding: 15px 20px;
        font-size: 20px;
        border: none;
        outline: none;
      }
    }
    .sendButton {
      color: #000;
      font-weight: 600;
      background-color: #e6bf72;
      padding: 20px 0;
      font-size: 25px;
      margin-top: 20px;
      display: block;
      width: 200px;
      text-align: center;
      transition: all 0.3s ease-in-out;
      outline: none;
      border: none;
      cursor: pointer;
      &:hover {
        background-color: #fff;
      }
    }
  }

  @media screen and (max-width: 780px) {
    flex-direction: column;
    .contactSOne_SocialsSide,
    .contactSOne_SendEmailSide {
      width: 100%;
    }
    .contactSOne_SocialsSide {
      text-align: center;
      margin-bottom: 40px;
      .icons {
        flex-direction: row;
        margin: auto;
        margin-top: 20px;
        a {
          p {
            display: none;
          }
        }
      }
    }
  }
  @media screen and (max-width: 600px) {
    padding: 30px 40px;
    .contactSOne_SocialsSide {
      h1 {
        font-size: 30px;
      }
      .icons {
        width: 200px;
        svg {
          width: 40px;
        }
      }
    }
    .contactSOne_SendEmailSide {
      .input_group {
        p {
          font-size: 20px;
        }
        input,
        textarea {
          font-size: 18px;
          padding: 15px;
        }
      }
      .sendButton {
        font-size: 20px;
        width: 180px;
      }
    }
  }
  @media screen and (max-width: 500px) {
    padding: 25px 20px;
    .contactSOne_SocialsSide {
      h1 {
        font-size: 28px;
      }
    }
  }
}
