.about_s_one {
  margin-top: 125px;
  padding-top: 30px;
  background-color: #eef0ed;
  .title {
    font-size: 40px;
    margin-bottom: 50px;
  }
  .one-row {
    background-color: #eef0ed;
    padding: 100px;
    .image,
    .text {
      width: 45%;
    }
    .image {
      img {
        width: 100%;
        border-radius: 10px;
      }
    }
    .text {
      .text-title {
        font-size: 35px;
        margin-bottom: 20px;
      }
      p {
        font-size: 22px;
      }
    }
    &.second {
      flex-direction: row-reverse;
      background-color: #18191d;
      color: white;
    }
  }
  @media screen and (max-width: 1024px) {
    .one-row {
      padding: 50px;
    }
  }

  @media screen and (max-width: 780px) {
    text-align: center;
    .title {
      font-size: 33px;
      margin-bottom: 20px;
    }
    .one-row {
      padding: 30px;
      flex-direction: column-reverse;
      .text {
        width: 100%;
        margin-bottom: 50px;
        .text-title {
          font-size: 28px;
        }
        p {
          font-size: 20px;
        }
      }
      .image {
        width: 100%;
      }
      &.second {
        flex-direction: column-reverse;
      }
    }
  }
  @media screen and (max-width: 600px) {
    margin-top: 100px;
    .one-row {
      padding: 20px;
      .text {
        margin-bottom: 30px;
      }
    }
  }
}
