.footer {
  padding: 40px 100px;
  background-color: #444;
  color: #ddd;
  // position: fixed;
  // width: 100%;
  // bottom: 0;
  .footer_top_s_row {
    width: 30%;
    .title {
      position: relative;
      font-size: 28px;
      font-weight: 500;
      text-transform: uppercase;
      &::before {
        position: absolute;
        left: 0;
        bottom: -2px;
        content: "";
        width: 25%;
        height: 3px;
        background-color: #d24242;
      }
    }
    .content {
      margin-top: 30px;
      a {
        display: flex;
        align-items: center;
        font-size: 18px;
        width: 150px;
        margin-bottom: 15px;
        color: #ddd;
        font-weight: 500;
        height: 35px;
        transition: all 0.2s ease-in-out;
        &:last-of-type {
          margin-bottom: 0;
        }
        svg {
          width: 35px;
          height: auto;
          margin-right: 10px;
          filter: invert(100%);
          transition: all 0.3s ease-in-out;
          transition: transform 0.6s ease-in-out;
        }
      }
      a {
        &:hover {
          color: black;
          svg {
            filter: invert(0);
            transform: rotate(360deg);
          }
        }
      }
    }
    &.quick-links,
    &.contact-us {
      .content {
        a {
          font-size: 20px;
          width: fit-content;
        }
      }
    }
  }
  .footer_bottom-section {
    text-align: center;
    font-size: 18px;
    hr {
      margin-top: 50px;
      margin-bottom: 20px;
    }
  }

  @media screen and (max-width: 1024px) {
    padding: 40px 80px;
  }
  @media screen and (max-width: 780px) {
    padding: 40px 40px;
  }
  @media screen and (max-width: 600px) {
    padding: 30px 30px;
    .footer_top_s_row {
      width: 50%;
      &.contact-us {
        width: 100%;
        margin-top: 50px;
        .content {
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: space-between;
          .same-width-on-mobile {
            width: 55%;
          }
        }
      }
    }
  }
  @media screen and (max-width: 500px) {
    .footer_top_s_row {
      width: 100%;
      .content {
        a {
          width: 100% !important;
        }
      }
      &.quick-links,
      &.contact-us {
        margin-top: 40px;
      }
    }
  }
}
