.coming-soon {
  height: 50vh;
  font-size: 22px;
  margin-top: 125px;
  padding: 100px 15% 100px 15%;
  text-align: center;
  color: ddd;
  background: #403b4a;
  background: linear-gradient(to bottom, #8c8d76, #403b4a);
  .coming-soon_date {
    margin: 50px;
    width: 70%;
    font-size: 25px;
  }
  @media screen and (max-width: 780px) {
    padding: 100px 20px 100px 20px;
    .coming-soon_date {
      width: 100%;
    }
  }
  @media screen and (max-width: 600px) {
    margin-top: 100px;
    font-size: 15px;
    .coming-soon_date {
      font-size: 20px;
    }
  }
  @media screen and (max-width: 500px) {
    .coming-soon_date {
      margin: 0;
      flex-wrap: wrap;
      h3 {
        flex-direction: column-reverse;
        width: 40%;
        margin-top: 50px;
      }
    }
  }
  @media screen and (max-width: 400px) {
    font-size: 12px;
    height: 80vh;
    .coming-soon_date {
      font-size: 15px;
    }
  }
}
