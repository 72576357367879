.home_s_one {
  height: 90vh;
  background-image: url("../../../../Assets/Images/home-background.jpg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-color: #313131ab;
  background-blend-mode: overlay;
  h1 {
    color: rgb(223, 223, 223);
    width: 60%;
    font-size: 45px;
    line-height: 70px;
    text-align: center;
    // font-family: "Alegreya", serif;
  }

  @media screen and (max-width: 1024px) {
    height: 70vh;
  }
  @media screen and (max-width: 780px) {
    h1 {
      width: 70%;
      font-size: 40px;
    }
  }
  @media screen and (max-width: 600px) {
    height: 40vh;
    h1 {
      width: 90%;
      font-size: 22px;
      line-height: 40px;
    }
  }
  @media screen and (max-width: 300px) {
    height: 30vh;
    h1 {
      width: 95%;
      font-size: 22px;
      line-height: 40px;
    }
  }
}
