.contact_s_one {
  margin-top: 125px;
  padding: 100px 0;
  overflow: hidden;
  .contactSOne_card {
    width: 25%;
    max-width: 450px;
    padding: 45px 0;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px,
      rgba(0, 0, 0, 0.23) 0px 6px 6px;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
    color: black;
    transition: box-shadow 0.4s ease;
    &:hover {
      box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px,
        rgba(0, 0, 0, 0.23) 0px 6px 6px;
    }
    h2 {
      font-size: 32px;
      text-align: center;
      font-weight: 600;
      margin: 10px 0 15px 0;
    }
    p {
      font-size: 20px;
      font-weight: 500;
      text-align: center;
      a {
        margin-left: 8px;
        color: black;
        transition: all 0.3s linear;
        display: inline-block;
        &:hover {
          transform: scale(1.1);
          color: rgb(116, 116, 116);
        }
      }
    }
    .icon {
      width: 60px;
      height: 60px;
      border-radius: 50%;
      background-color: #fcaf3b;
      svg {
        width: 55%;
      }
    }

    @media screen and (max-width: 780px) {
      width: 32%;
    }
    @media screen and (max-width: 600px) {
      width: 80%;
      margin-bottom: 50px;
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  .contactSOne_map {
    width: 90%;
    max-width: 1000px;
    height: 500px;
    margin: 100px auto 20px auto;
    iframe {
      width: 100%;
      height: 100%;
    }
    @media screen and (max-width: 780px) {
      margin: 50px auto;
    }
  }

  @media screen and (max-width: 780px) {
    margin: 70px 0;
  }
  @media screen and (max-width: 600px) {
    margin: 50px 0;
    padding-bottom: 30px;
    margin-bottom: 0;
  }
}
