.home_s_three {
  padding-top: 10px;
  padding-bottom: 100px;
  .homeSThree_text {
    width: 40%;
    max-width: 600px;
    padding: 60px 30px;
    text-align: center;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px,
      rgba(0, 0, 0, 0.23) 0px 6px 6px;
    h1 {
      line-height: 1.8;
      font-size: 31px;
    }

    @media screen and (max-width: 1024px) {
      width: 45%;
      padding: 40px 30px;
    }
    @media screen and (max-width: 780px) {
      width: 80%;
      max-width: 90%;
      padding: 40px 20px;
      &.first {
        margin-bottom: 50px;
      }
    }
    @media screen and (max-width: 600px) {
      h1 {
        line-height: normal;
        font-size: 28px;
      }
    }
    @media screen and (max-width: 500px) {
      h1 {
        font-size: 25px;
      }
    }
  }
  @media screen and (max-width: 780px) {
    padding-bottom: 70px;
  }
  @media screen and (max-width: 600px) {
    padding-bottom: 60px;
  }
}
