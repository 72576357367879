.projects_s_one {
  margin-top: 145px;
  overflow: hidden;
  .top {
    width: 90%;
    margin: auto;
    margin-bottom: 60px;
    font-weight: normal;
    h1 {
      margin: 20px 0 10px 0;
      font-size: 40px;
    }
    h3 {
      width: 80%;
      font-size: 25px;
      font-weight: 500;
      line-height: 1.8;
    }
  }
  .bottom {
    padding: 4% 0;
    color: #fff;
    background-color: #18191d;
  }
  .row {
    width: 90%;
    max-width: 1500px;
    margin: auto;
    margin-bottom: 50px;
  }
  .images {
    margin-top: 20px;
    .image {
      width: 32%;
      max-height: 295px;
      margin-bottom: 30px;
      > div {
        width: 100%;
        height: 100%;
      }
      img {
        width: 100%;
        height: 100%;
        cursor: pointer;
      }
      .react-simple-image-viewer__modal {
        z-index: 1000;
        padding: 10px 0;
        img {
          cursor: initial;
          width: initial;
          height: initial;
        }
      }
    }
  }

  .button {
    margin: auto;
    margin-top: 60px;
    padding: 10px 20px;
    width: 230px;
    font-size: 22px;
    text-align: center;
    color: black;
    border: 2px solid black;
    transition: all 0.3s ease-in !important;
    background-color: #787c8d;
    display: block;
    &:hover {
      transform: scale(1.1) !important;
      border-radius: 20px;
      background-color: white;
    }
  }

  @media screen and (max-width: 600px) {
    margin-top: 110px;
    .top {
      width: 95%;
      h3 {
        width: 95%;
      }
    }
    .images {
      .image {
        width: 48%;
      }
    }
    .row {
      &:last-of-type {
        margin-bottom: 10px;
      }
    }
    .button {
      font-size: 20px;
      width: 180px;
      margin-top: 0;
    }
  }
  @media screen and (max-width: 500px) {
    .top {
      h3 {
        width: 100%;
      }
    }
    .row {
      text-align: center;
    }
    .images {
      justify-content: center;
      .image {
        width: 90%;
      }
    }
  }
}
