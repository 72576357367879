.block {
  display: block;
}
.flex {
  display: flex;
}
.flex-wrap {
  flex-wrap: wrap;
}
.flex-inline {
  display: inline-flex;
}
.row {
  flex-direction: row;
}
.row-reverse {
  flex-direction: row-reverse;
}
.column {
  flex-direction: column;
}
.column-reverse {
  flex-direction: column-reverse;
}
.align-center {
  align-items: center;
}
.align-end {
  align-items: flex-end;
}
.justify-center {
  justify-content: center;
}
.justify-end {
  justify-content: flex-end;
}
.between {
  justify-content: space-between;
}
.around {
  justify-content: space-around;
}
.evenly {
  justify-content: space-evenly;
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
a {
  text-decoration: none;
}
.disabled-link {
  pointer-events: none;
}
.margin-top {
  margin-top: 125px;

  @media screen and (max-width: 600px) {
    margin-top: 100px;
  }
}
