.home_s_four {
  background: linear-gradient(rgb(255, 255, 255), rgba(154, 154, 154, 0.65));
  padding: 10px 0 20px 0;
  .title {
    font-size: 40px;
    margin-bottom: 50px;
  }
  .homeSFour_row {
    width: 85%;
    max-width: 1500px;
    &:last-of-type {
      margin-top: 40px;
    }
    .rows_title {
      font-size: 30px;
      margin-bottom: 20px;
    }
    .rows_images {
      width: 100%;
    }
  }

  .alice-carousel {
    width: 95%;
    .alice-carousel__dots {
      display: none;
    }
    .alice-carousel__prev-btn,
    .alice-carousel__next-btn {
      width: fit-content;
      height: fit-content;
      display: flex;
      align-items: center;
      font-size: 70px;
      padding: 0;
      position: absolute;
      top: calc(50% - 47.5px);
    }
    .alice-carousel__prev-btn {
      left: -50px;
    }
    .alice-carousel__next-btn {
      right: -50px;
    }
    .alice-carousel__prev-btn-item,
    .alice-carousel__next-btn-item {
      padding: 0;
      &.__inactive {
        display: none;
      }
    }
    img {
      width: 100%;
      height: 100%;
    }
    .item {
      margin: auto;
      width: 95%;
      height: 300px;
    }
    .alice-carousel__prev-btn-item,
    .alice-carousel__next-btn-item {
      color: black;
    }
    .alice-carousel__prev-btn-item,
    .alice-carousel__next-btn-item {
      &:hover {
        color: rgb(116, 116, 116);
      }
    }
  }
  .homeSFour_button {
    margin-top: 60px;
    padding: 10px 20px;
    width: 230px;
    font-size: 22px;
    text-align: center;
    color: black;
    border: 2px solid black;
    transition: all 0.3s ease-in !important;
    &:hover {
      transform: scale(1.1) !important;
      border-radius: 20px;
      background-color: white;
    }
  }

  @media screen and (max-width: 780px) {
    .title {
      margin-bottom: 30px;
    }
    .alice-carousel {
      .alice-carousel__prev-btn {
        left: -40px;
      }
      .alice-carousel__next-btn {
        right: -40px;
      }
      .alice-carousel__dots {
        display: block;
        margin-top: 20px;
        .alice-carousel__dots-item {
          background-color: #ffff;
          &.__active {
            background-color: #000;
          }
        }
      }
    }
  }
  @media screen and (max-width: 600px) {
    .title {
      font-size: 32px;
    }
    .homeSFour_row {
      .rows_title {
        font-size: 25px;
      }
    }
    .homeSFour_button {
      margin-top: 40px;
      font-size: 18px;
      width: 200px;
    }
    .alice-carousel {
      .alice-carousel__prev-btn,
      .alice-carousel__next-btn {
        font-size: 50px;
      }
      .alice-carousel__prev-btn {
        left: -30px;
      }
      .alice-carousel__next-btn {
        right: -30px;
      }
    }
  }
}
