.mobile_navbar {
  width: 60%;
  height: 100%;
  position: fixed;
  left: -500px;
  top: 125px;
  background-color: white;
  transition: all 0.8s ease-in-out;
  box-shadow: 10px 0 5px -5px #888;
  z-index: 1000;
  display: none;
  &.true {
    transform: translateX(500px);
  }

  .mobile_navbar_links {
    margin-top: 50px;
    a {
      font-size: 25px;
      font-weight: 550;
      margin-bottom: 45px;
      color: #4a4c50;
    }
  }
  .select-language {
    margin: auto;
  }

  @media screen and(max-width: 768px) {
    display: block;
  }
  @media screen and(max-width: 600px) {
    top: 100px;
  }
}
