.home_s_two {
  margin: 100px 0;
  overflow: hidden;
  .homeSTwo_card {
    width: 25%;
    max-width: 400px;
    height: 120px;
    h2 {
      font-size: 25px;
      text-align: center;
    }
    .icon {
      width: 60px;
      height: 60px;
      border-radius: 50%;
      margin-bottom: 25px;
      background-color: #fcaf3b;
      svg {
        width: 55%;
      }
    }

    @media screen and (max-width: 780px) {
      width: 32%;
    }
    @media screen and (max-width: 600px) {
      width: 100%;
      margin-bottom: 50px;
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
  @media screen and (max-width: 780px) {
    margin: 70px 0;
  }
  @media screen and (max-width: 600px) {
    margin: 50px 0;
  }
}
