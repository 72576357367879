.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 125px;
  padding: 15px 30px;
  z-index: 500;
  transition: all 0.3s linear;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  background-color: white;

  &.true {
    box-shadow: 0 5px 5px rgba(182, 182, 182, 0.75);
  }

  .header_icon {
    height: 90%;
    max-width: 500px;
    img {
      max-height: 100%;
    }
  }

  .header_links {
    width: 35%;
    a {
      font-size: 18px;
      color: #4a4c50;
      text-transform: uppercase;
      font-weight: 600;
      transition: all 0.3s ease-in;
      position: relative;
      text-decoration: none;
      display: inline-block;
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
        Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

      &::after {
        display: block;
        content: '';
        border-bottom: solid 3px rgba(64, 66, 72, 0.5);
        transform: scaleX(0);
        transition: transform 0.35s ease-in-out;
        transform-origin: 100% 50%;
      }
      &:hover {
        color: rgba(47, 49, 53, 0.5);
        &::after {
          transform: scaleX(1);
          transform-origin: 0 50%;
        }
      }
      &.active {
        color: rgba(47, 49, 53, 0.5);
        display: block;
        content: '';
        border-bottom: solid 3px rgba(64, 66, 72, 0.5);
        transform: scaleX(1);
        &::after {
          transform: initial;
          all: unset;
        }
        &:hover {
          color: #4a4c50;
          border-bottom: solid 3px #4a4c50;
        }
      }
    }
  }

  .header_contact_language {
    width: 20%;
    font-family: Roboto, sans-serif;
    .header_contact-cont {
      a {
        color: #4a4c50;
        font-weight: 600;
        font-size: 18px;
        position: relative;
        text-decoration: none;
        display: inline-block;
        width: fit-content;
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
          Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
        &:hover {
          color: rgba(64, 66, 72, 0.5);
        }
        &::after {
          display: block;
          content: '';
          border-bottom: solid 3px rgba(64, 66, 72, 0.5);
          transform: scaleX(0);
          transition: transform 0.35s ease-in-out;
          transform-origin: 100% 50%;
        }
        &:hover {
          &::after {
            transform: scaleX(1);
            transform-origin: 0 50%;
          }
        }
      }
    }
    .header-contact-lang {
      width: fit-content;
      margin-left: auto;
      margin-top: 5px;
    }
  }

  .header_hamburger {
    display: none;
    position: relative;
    height: 100%;
  }

  @media screen and(max-width: 768px) {
    box-shadow: 0 5px 5px rgba(182, 182, 182, 0.75);
    .header_links,
    .header-contact-lang {
      display: none;
    }
    .header_icon,
    .header_contact_language {
      width: 30%;
    }
    .header_hamburger {
      display: block;
      width: 30px;
      margin-left: -200px;
    }
  }

  @media screen and (max-width: 600px) {
    height: 100px;
    .header_icon {
      width: 30%;
    }
    .header_contact-cont,
    .header_contact_language {
      display: none;
    }

    .header_hamburger {
      margin-left: initial;
    }
  }
  @media screen and (max-width: 500px) {
    .header_icon {
      width: 40%;
    }
  }
  @media screen and (max-width: 400px) {
    .header_icon {
      height: 70%;
    }
  }
}
