.select-language {
  position: relative;
  width: 180px;
  max-width: 200px;
  height: 45px;
  padding: 5px 10px;
  cursor: pointer;
  color: white;
  letter-spacing: 2px;
  background: linear-gradient(
    90deg,
    rgba(74, 76, 80, 0.637),
    rgba(74, 76, 80, 1)
  );
  border-radius: 5px;
  font-size: 18px;
  img {
    width: 40px;
    margin-right: 5px;
  }
  .arrow {
    width: 20px;
    height: fit-content;
    margin-left: auto;
    margin-right: 5px;
    transition: all 0.3s ease-in-out;
    color: white;
    g {
      fill: white;
    }
  }
  .select-languge_dropdown {
    position: absolute;
    top: 45px;
    left: 0;
    width: 180px;
    max-height: 0;
    overflow: hidden;
    visibility: hidden;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }
  &:hover {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    .arrow {
      transform: rotate(180deg);
    }
    .select-languge_dropdown {
      max-height: 135px;
      visibility: visible;
    }
  }
  .single-language {
    padding: 5px 10px;
    height: 45px;
    background: linear-gradient(
      90deg,
      rgba(74, 76, 80, 0.637),
      rgba(74, 76, 80, 1)
    );
    &:hover {
      background: linear-gradient(
        90deg,
        rgba(39, 39, 39, 0.5),
        rgb(39, 39, 39)
      );
    }
  }

  @media screen and (max-width: 780px) {
    -webkit-tap-highlight-color: transparent;
    &:hover {
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
      .select-languge_dropdown {
        max-height: 0;
        visibility: hidden;
      }
      .arrow {
        transform: rotate(0);
      }
    }
    &.true {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      .arrow {
        transform: rotate(180deg);
        -webkit-tap-highlight-color: transparent;
      }
      .select-languge_dropdown {
        max-height: 135px;
        visibility: visible;
      }
    }
  }
  @media screen and (max-width: 300px) {
    width: 150px;
    max-width: 180px;
    img {
      width: 30px;
      margin-right: 3px;
    }
    .arrow {
      width: 12px;
      margin-right: 0;
    }

    .select-languge_dropdown {
      width: 150px;
    }
  }
}
